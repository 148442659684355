// src/theme.ts
import { createTheme } from "@mantine/core";
import "./main.css";

export const theme = createTheme({
	fontFamily: "Neutra",
	themeMainColor: "#effb01",
	themeBlackColor: "#000000",
	// ... other theme override properties
});
