exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-background-card-background-card-jsx": () => import("./../../../src/pages/components/backgroundCard/backgroundCard.jsx" /* webpackChunkName: "component---src-pages-components-background-card-background-card-jsx" */),
  "component---src-pages-components-catalogue-catalogue-jsx": () => import("./../../../src/pages/components/Catalogue/Catalogue.jsx" /* webpackChunkName: "component---src-pages-components-catalogue-catalogue-jsx" */),
  "component---src-pages-components-features-features-jsx": () => import("./../../../src/pages/components/features/features.jsx" /* webpackChunkName: "component---src-pages-components-features-features-jsx" */),
  "component---src-pages-components-footer-footer-jsx": () => import("./../../../src/pages/components/footer/footer.jsx" /* webpackChunkName: "component---src-pages-components-footer-footer-jsx" */),
  "component---src-pages-components-header-header-jsx": () => import("./../../../src/pages/components/header/header.jsx" /* webpackChunkName: "component---src-pages-components-header-header-jsx" */),
  "component---src-pages-components-hero-hero-component-box-hero-component-box-jsx": () => import("./../../../src/pages/components/hero/heroComponentBox/heroComponentBox.jsx" /* webpackChunkName: "component---src-pages-components-hero-hero-component-box-hero-component-box-jsx" */),
  "component---src-pages-components-hero-hero-component-box-label-hero-component-box-label-jsx": () => import("./../../../src/pages/components/hero/heroComponentBoxLabel/heroComponentBoxLabel.jsx" /* webpackChunkName: "component---src-pages-components-hero-hero-component-box-label-hero-component-box-label-jsx" */),
  "component---src-pages-components-hero-hero-component-hero-component-jsx": () => import("./../../../src/pages/components/hero/heroComponent/heroComponent.jsx" /* webpackChunkName: "component---src-pages-components-hero-hero-component-hero-component-jsx" */),
  "component---src-pages-components-hero-hero-jsx": () => import("./../../../src/pages/components/hero/hero.jsx" /* webpackChunkName: "component---src-pages-components-hero-hero-jsx" */),
  "component---src-pages-components-hero-image-right-hero-image-right-jsx": () => import("./../../../src/pages/components/HeroImageRight/HeroImageRight.jsx" /* webpackChunkName: "component---src-pages-components-hero-image-right-hero-image-right-jsx" */),
  "component---src-pages-hospitality-js": () => import("./../../../src/pages/hospitality.js" /* webpackChunkName: "component---src-pages-hospitality-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-residencial-js": () => import("./../../../src/pages/residencial.js" /* webpackChunkName: "component---src-pages-residencial-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-sobrenosotros-js": () => import("./../../../src/pages/sobrenosotros.js" /* webpackChunkName: "component---src-pages-sobrenosotros-js" */)
}

